/// <reference types="./view.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import { toList, prepend as listPrepend } from "../../gleam.mjs";
import { var$ } from "../../grille_pain/internals/css.mjs";
import * as $model from "../../grille_pain/internals/data/model.mjs";
import { Model } from "../../grille_pain/internals/data/model.mjs";
import * as $msg from "../../grille_pain/internals/data/msg.mjs";
import * as $toast from "../../grille_pain/internals/data/toast.mjs";
import * as $progress_bar from "../../grille_pain/internals/view/progress_bar.mjs";
import * as $theme from "../../grille_pain/internals/view/theme.mjs";
import * as $level from "../../grille_pain/toast/level.mjs";

function select_on_click_action(toast) {
  return $bool.lazy_guard(
    toast.sticky,
    $attribute.none,
    () => {
      return $event.on_click(new $msg.ToastTimedOut(toast.id, toast.iteration));
    },
  );
}

function toast_progress_bar(toast) {
  return $bool.lazy_guard(
    toast.sticky,
    $element.none,
    () => { return $progress_bar.view(toast); },
  );
}

function toast_wrapper(toast, attributes, children) {
  let transition = $attribute.class$(
    (() => {
      let $ = toast.displayed;
      if ($ instanceof $toast.Show) {
        return "toast-wrapper-all";
      } else if ($ instanceof $toast.WillHide) {
        return "toast-wrapper-all";
      } else {
        return "toast-wrapper-right";
      }
    })(),
  );
  let right = [
    "right",
    (() => {
      let $ = toast.displayed;
      if ($ instanceof $toast.Show) {
        return "0px";
      } else if ($ instanceof $toast.WillShow) {
        let width = var$("grille_pain-width", "320px");
        return ("calc(-1 * " + width) + " - 100px)";
      } else {
        let width = var$("grille_pain-width", "320px");
        return ("calc(-1 * " + width) + " - 100px)";
      }
    })(),
  ];
  let top = ["top", $int.to_string(toast.bottom) + "px"];
  return $html.div(
    listPrepend(
      $attribute.class$("toast-wrapper"),
      listPrepend(
        transition,
        listPrepend($attribute.style(toList([right, top])), attributes),
      ),
    ),
    children,
  );
}

function wrapper_dom_classes(toast) {
  let displayed = (() => {
    let $ = toast.displayed;
    if ($ instanceof $toast.Show) {
      return "show";
    } else if ($ instanceof $toast.WillHide) {
      return "will-hide";
    } else {
      return "will-show";
    }
  })();
  return $attribute.classes(
    toList([
      ["grille_pain-toast", true],
      ["grille_pain-toast-" + $int.to_string(toast.id), true],
      ["grille_pain-toast-" + displayed, true],
    ]),
  );
}

function toast_colors(level) {
  let $ = $theme.color(level);
  let background = $[0];
  let text_color = $[1];
  let level$1 = $level.to_string(level);
  let background_ = ("grille_pain-" + level$1) + "-background";
  let text = ("grille_pain-" + level$1) + "-text-color";
  return $attribute.style(
    toList([
      ["background", var$(background_, background)],
      ["color", var$(text, text_color)],
    ]),
  );
}

function toast_container(toast, children) {
  let mouse_enter = $event.on_mouse_enter(new $msg.UserEnteredToast(toast.id));
  let mouse_leave = $event.on_mouse_leave(new $msg.UserLeavedToast(toast.id));
  let colors = toast_colors(toast.level);
  let toast$1 = $attribute.class$("toast");
  return $html.div(
    toList([mouse_enter, mouse_leave, toast$1, colors]),
    children,
  );
}

export function toast_content(attributes, children) {
  return $html.div(
    listPrepend($attribute.class$("toast-content"), attributes),
    children,
  );
}

function view_toast(toast) {
  let on_hide = select_on_click_action(toast);
  return toast_wrapper(
    toast,
    toList([
      wrapper_dom_classes(toast),
      $attribute.attribute("data-id", $int.to_string(toast.id)),
    ]),
    toList([
      toast_container(
        toast,
        toList([
          toast_content(toList([on_hide]), toList([$html.text(toast.message)])),
          toast_progress_bar(toast),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let toasts = model.toasts;
  return $element.keyed(
    (_capture) => {
      return $html.div(toList([$attribute.class$("grille-pain")]), _capture);
    },
    $list.map(
      toasts,
      (toast) => {
        let id = $int.to_string(toast.id);
        return [id, view_toast(toast)];
      },
    ),
  );
}
