/// <reference types="./msg.d.mts" />
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { CustomType as $CustomType } from "../../../gleam.mjs";
import * as $global from "../../../grille_pain/internals/global.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

export class BrowserUpdatedToasts extends $CustomType {}

export class LustreComputedToasts extends $CustomType {}

export class LustreRequestedAnimationFrame extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ToastHidDisplay extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class ToastTimedOut extends $CustomType {
  constructor(id, iteration) {
    super();
    this.id = id;
    this.iteration = iteration;
  }
}

export class UserAddedToast extends $CustomType {
  constructor(uuid, message, level, timeout, sticky) {
    super();
    this.uuid = uuid;
    this.message = message;
    this.level = level;
    this.timeout = timeout;
    this.sticky = sticky;
  }
}

export class UserEnteredToast extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class UserHidToast extends $CustomType {
  constructor(uuid) {
    super();
    this.uuid = uuid;
  }
}

export class UserLeavedToast extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}
