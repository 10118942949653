/// <reference types="./error.d.mts" />
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../../lustre/lustre.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class LustreError extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ContextError extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function lustre(res) {
  return $result.map_error(res, (var0) => { return new LustreError(var0); });
}

export function context(res, context) {
  return $result.replace_error(res, new ContextError(context));
}
