/// <reference types="./model.d.mts" />
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType } from "../../../gleam.mjs";
import * as $toast from "../../../grille_pain/internals/data/toast.mjs";
import { Toast } from "../../../grille_pain/internals/data/toast.mjs";
import * as $global from "../../../grille_pain/internals/global.mjs";
import * as $shadow from "../../../grille_pain/internals/shadow.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

export class Model extends $CustomType {
  constructor(toasts, id, timeout, root, next_frame, to_show) {
    super();
    this.toasts = toasts;
    this.id = id;
    this.timeout = timeout;
    this.root = root;
    this.next_frame = next_frame;
    this.to_show = to_show;
  }
}

export class ToShow extends $CustomType {
  constructor(toast_id, timeout, sticky) {
    super();
    this.toast_id = toast_id;
    this.timeout = timeout;
    this.sticky = sticky;
  }
}

export function new$(root, timeout) {
  let toasts = toList([]);
  let id = 0;
  return new Model(toasts, id, timeout, root, new $option.None(), toList([]));
}

export function add(model, external_id, message, level, sticky, timeout) {
  let animation_duration = $option.unwrap(timeout, model.timeout);
  let new_toast = $toast.new$(
    external_id,
    model.id,
    message,
    level,
    animation_duration,
    sticky,
    model.root,
  );
  let new_toasts = listPrepend(new_toast, model.toasts);
  let new_id = model.id + 1;
  let to_show = listPrepend(
    new ToShow(new_toast.id, timeout, sticky),
    model.to_show,
  );
  let _record = model;
  return new Model(
    new_toasts,
    new_id,
    _record.timeout,
    _record.root,
    _record.next_frame,
    to_show,
  );
}

function update_toast(model, id, updater) {
  let toasts = $list.map(
    model.toasts,
    (toast) => {
      let $ = id === toast.id;
      if ($) {
        return updater(toast);
      } else {
        return toast;
      }
    },
  );
  let _record = model;
  return new Model(
    toasts,
    _record.id,
    _record.timeout,
    _record.root,
    _record.next_frame,
    _record.to_show,
  );
}

export function show(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $global.now();
      let _record = toast;
      return new Toast(
        _record.external_id,
        _record.id,
        _record.sticky,
        _record.message,
        new $toast.Show(),
        true,
        _record.remaining,
        now,
        _record.iteration,
        _record.bottom,
        _record.level,
        _record.animation_duration,
      );
    },
  );
}

export function hide(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let _record = toast;
      return new Toast(
        _record.external_id,
        _record.id,
        _record.sticky,
        _record.message,
        new $toast.WillHide(),
        _record.running,
        _record.remaining,
        _record.last_schedule,
        _record.iteration,
        _record.bottom,
        _record.level,
        _record.animation_duration,
      );
    },
  );
}

export function stop(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $global.now();
      let duration = now - toast.last_schedule;
      let remaining = toast.remaining - duration;
      let iteration = toast.iteration + 1;
      let _record = toast;
      return new Toast(
        _record.external_id,
        _record.id,
        _record.sticky,
        _record.message,
        _record.displayed,
        false,
        remaining,
        _record.last_schedule,
        iteration,
        _record.bottom,
        _record.level,
        _record.animation_duration,
      );
    },
  );
}

export function resume(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $global.now();
      let _record = toast;
      return new Toast(
        _record.external_id,
        _record.id,
        _record.sticky,
        _record.message,
        _record.displayed,
        true,
        _record.remaining,
        now,
        _record.iteration,
        _record.bottom,
        _record.level,
        _record.animation_duration,
      );
    },
  );
}

export function remove(model, id) {
  let new_toasts = $list.filter(
    model.toasts,
    (toast) => { return toast.id !== id; },
  );
  let _record = model;
  return new Model(
    new_toasts,
    _record.id,
    _record.timeout,
    _record.root,
    _record.next_frame,
    _record.to_show,
  );
}

export function update_bottom_positions(model) {
  let _record = model;
  return new Model(
    $list.map(
      model.toasts,
      (toast) => {
        let $ = toast.displayed;
        if ($ instanceof $toast.WillHide) {
          return toast;
        } else if ($ instanceof $toast.Show) {
          let _record$1 = toast;
          return new $toast.Toast(
            _record$1.external_id,
            _record$1.id,
            _record$1.sticky,
            _record$1.message,
            _record$1.displayed,
            _record$1.running,
            _record$1.remaining,
            _record$1.last_schedule,
            _record$1.iteration,
            $toast.compute_bottom_position(model.root, toast.id),
            _record$1.level,
            _record$1.animation_duration,
          );
        } else {
          let _record$1 = toast;
          return new $toast.Toast(
            _record$1.external_id,
            _record$1.id,
            _record$1.sticky,
            _record$1.message,
            _record$1.displayed,
            _record$1.running,
            _record$1.remaining,
            _record$1.last_schedule,
            _record$1.iteration,
            $toast.compute_bottom_position(model.root, toast.id),
            _record$1.level,
            _record$1.animation_duration,
          );
        }
      },
    ),
    _record.id,
    _record.timeout,
    _record.root,
    _record.next_frame,
    _record.to_show,
  );
}
