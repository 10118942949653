/// <reference types="./spreadsheet_selector.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $set from "../../../gleam_stdlib/gleam/set.mjs";
import * as $file from "../../data/ephemeral/file.mjs";
import * as $spreadsheet from "../../data/spreadsheet.mjs";
import { toList, CustomType as $CustomType, isEqual } from "../../gleam.mjs";

export class SpreadsheetSelector extends $CustomType {
  constructor(selected_sheet, columns_type, invalid_lines, xlsx, file, skip_last_sheet, processed_sheets, relevant_sheets, processing) {
    super();
    this.selected_sheet = selected_sheet;
    this.columns_type = columns_type;
    this.invalid_lines = invalid_lines;
    this.xlsx = xlsx;
    this.file = file;
    this.skip_last_sheet = skip_last_sheet;
    this.processed_sheets = processed_sheets;
    this.relevant_sheets = relevant_sheets;
    this.processing = processing;
  }
}

export function new$() {
  return new SpreadsheetSelector(
    new $option.None(),
    toList([]),
    toList([]),
    new $option.None(),
    new $option.None(),
    false,
    $set.new$(),
    $set.new$(),
    false,
  );
}

export function update_xlsx(new_proposal, xlsx) {
  let _record = new_proposal;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    _record.columns_type,
    _record.invalid_lines,
    new $option.Some(xlsx),
    _record.file,
    _record.skip_last_sheet,
    _record.processed_sheets,
    _record.relevant_sheets,
    _record.processing,
  );
}

export function update_selected_sheet(new_proposal, name) {
  let _record = new_proposal;
  return new SpreadsheetSelector(
    new $option.Some(name),
    _record.columns_type,
    _record.invalid_lines,
    _record.xlsx,
    _record.file,
    _record.skip_last_sheet,
    _record.processed_sheets,
    _record.relevant_sheets,
    _record.processing,
  );
}

export function reset_columns_type(new_proposal, sheet) {
  let columns_type = (() => {
    let _pipe = new_proposal.columns_type;
    return $list.key_set(_pipe, sheet, $dict.new$());
  })();
  let _record = new_proposal;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    columns_type,
    _record.invalid_lines,
    _record.xlsx,
    _record.file,
    _record.skip_last_sheet,
    _record.processed_sheets,
    _record.relevant_sheets,
    _record.processing,
  );
}

export function reset_invalid_lines(new_proposal, sheet) {
  let invalid_lines = (() => {
    let _pipe = new_proposal.invalid_lines;
    return $list.key_set(_pipe, sheet, $dict.new$());
  })();
  let _record = new_proposal;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    _record.columns_type,
    invalid_lines,
    _record.xlsx,
    _record.file,
    _record.skip_last_sheet,
    _record.processed_sheets,
    _record.relevant_sheets,
    _record.processing,
  );
}

export function skip_last_sheet(new_proposal) {
  let _record = new_proposal;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    _record.columns_type,
    _record.invalid_lines,
    _record.xlsx,
    _record.file,
    true,
    _record.processed_sheets,
    _record.relevant_sheets,
    _record.processing,
  );
}

export function skip_selected_sheet(new_proposal, current_sheet, next_sheet) {
  let _pipe = new_proposal;
  let _pipe$1 = reset_columns_type(_pipe, current_sheet);
  let _pipe$2 = reset_invalid_lines(_pipe$1, current_sheet);
  return (() => {
    if (next_sheet === "") {
      return skip_last_sheet;
    } else {
      return (_capture) => {
        return update_selected_sheet(_capture, next_sheet);
      };
    }
  })()(_pipe$2);
}

export function update_column_type(new_proposal, sheet, index, value) {
  let columns_type = (() => {
    let _pipe = new_proposal.columns_type;
    let _pipe$1 = $list.key_find(_pipe, sheet);
    let _pipe$2 = $result.unwrap(_pipe$1, $dict.new$());
    let _pipe$3 = $dict.fold(
      _pipe$2,
      $dict.new$(),
      (d, k, v) => {
        return $bool.guard(
          isEqual(v, value),
          d,
          () => { return $dict.insert(d, k, v); },
        );
      },
    );
    let _pipe$4 = $dict.insert(_pipe$3, index, value);
    return ((_capture) => {
      return $list.key_set(new_proposal.columns_type, sheet, _capture);
    })(_pipe$4);
  })();
  let _record = new_proposal;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    columns_type,
    _record.invalid_lines,
    _record.xlsx,
    _record.file,
    _record.skip_last_sheet,
    _record.processed_sheets,
    _record.relevant_sheets,
    _record.processing,
  );
}

export function update_invalid_line(new_proposal, sheet, index, validity) {
  let invalid_lines = (() => {
    let _pipe = new_proposal.invalid_lines;
    let _pipe$1 = $list.key_find(_pipe, sheet);
    let _pipe$2 = $result.unwrap(_pipe$1, $dict.new$());
    let _pipe$3 = $dict.insert(_pipe$2, index, validity);
    return ((_capture) => {
      return $list.key_set(new_proposal.invalid_lines, sheet, _capture);
    })(_pipe$3);
  })();
  let _record = new_proposal;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    _record.columns_type,
    invalid_lines,
    _record.xlsx,
    _record.file,
    _record.skip_last_sheet,
    _record.processed_sheets,
    _record.relevant_sheets,
    _record.processing,
  );
}

export function get_language(selector) {
  return $option.then$(selector.xlsx, (xlsx) => { return xlsx.language; });
}

export function update_processing(selector, processing) {
  let _record = selector;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    _record.columns_type,
    _record.invalid_lines,
    _record.xlsx,
    _record.file,
    _record.skip_last_sheet,
    _record.processed_sheets,
    _record.relevant_sheets,
    processing,
  );
}

export function update_processed_sheets(selector, sheet) {
  let processed_sheets = (() => {
    let _pipe = selector.processed_sheets;
    return $set.insert(_pipe, sheet);
  })();
  let processing = (() => {
    let $ = selector.xlsx;
    if ($ instanceof $option.Some) {
      let xlsx = $[0];
      let all_sheets = (() => {
        let _pipe = $list.map(xlsx.data, (s) => { return s.name; });
        return $set.from_list(_pipe);
      })();
      return !$set.is_subset(all_sheets, processed_sheets);
    } else {
      return true;
    }
  })();
  let _record = selector;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    _record.columns_type,
    _record.invalid_lines,
    _record.xlsx,
    _record.file,
    _record.skip_last_sheet,
    processed_sheets,
    _record.relevant_sheets,
    processing,
  );
}

export function update_relevant_sheets(selector, sheet) {
  let relevant_sheets = (() => {
    let _pipe = selector.relevant_sheets;
    return $set.insert(_pipe, sheet);
  })();
  let _record = selector;
  return new SpreadsheetSelector(
    _record.selected_sheet,
    _record.columns_type,
    _record.invalid_lines,
    _record.xlsx,
    _record.file,
    _record.skip_last_sheet,
    _record.processed_sheets,
    relevant_sheets,
    _record.processing,
  );
}
